export const getLoginReferer = () => localStorage.getItem('loginReferer');

export const isIOS = () => {
	let isios = false;

	if (process.browser) {
		const userAgent = navigator.userAgent.toLowerCase();
		isios = /iphone|ipod|ipad/.test(userAgent);
	}

	return isios;
};

export const isIOSWebView = () => {
	let _isIOSWebView = false;

	if (process.browser) {
		const userAgent = navigator.userAgent.toLowerCase();
		_isIOSWebView = /iphone|ipod|ipad/.test(userAgent) && userAgent.indexOf('safari') === -1;
	}

	return _isIOSWebView;
};

// export const updateReferer = (referer: string) => {
// 	if (!/topgimil|nid.naver|localhost/.test(referer) && referer !== '') {
// 		localStorage.removeItem('referer');
// 		localStorage.setItem('referer', referer);
// 	}
// };

// export const updateSId = (id: string) => {
// 	localStorage.setItem('SId', id);
// };

export const isIE = () => {
	let isie = false;

	if (process.browser) {
		const userAgent = navigator.userAgent.toLowerCase();
		isie = /trident|msie/.test(userAgent);
	}

	return isie;
};