import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { currencyState } from "@src/types/reduxState"

const initialState: currencyState = {
    dollartowon: 0,
};

const currencyReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        // 로그인 시 유저 데이터 저장
        setCurrency(state, action: PayloadAction<currencyState>)
        {
            state.dollartowon = action.payload.dollartowon;
        },
    }
});

export const { setCurrency } = { ...currencyReducer.actions };

export default currencyReducer.reducer;