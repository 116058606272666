import media from 'src/styles/media';
import { ThemeType } from 'src/types/theme';
import { createGlobalStyle } from 'styled-components';
import updatedMedia from './media';

const lightTheme: ThemeType = {
	...media,
	breakpoint: {
		...updatedMedia,
	},
	body: '#FFF',
  text: '#363537',
  textHighlight: '#1c1c1c',
  contentLight: "#41b0eb",
	contentDark: "#168dcd",
  background: '#363537',
  boxDark: "#262626",
  boxLight: "#565656",
}

const darkTheme: ThemeType = {
	...media,
	breakpoint: {
		...updatedMedia,
	},
	body: '#110831',
  text: 'white',
  textHighlight: '#CDCDCD',
  contentLight: "#41b0eb",
	contentDark: "#168dcd",
  background: '#999',
  boxDark: "#262626",
  boxLight: "#565656",
}

const GlobalStyles = createGlobalStyle`

  body {
    background: ${({ theme }: { theme: any }) => theme.body};
    color: ${({ theme }: { theme: any }) => theme.text};
    font-family: 'Encode Sans Expanded', Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
`

export { lightTheme, darkTheme, GlobalStyles };
