import { css, FlattenSimpleInterpolation } from 'styled-components';

export type BreakPointType = {
	labtopL?: number;
	labtop?: number;
	xlargeTablet?: number;
	largeTablet?: number;
	tablet?: number;
	smallTablet?: number;
	mobile?: number;
	mediumMobile?: number;
	smallMobile?: number;
};

export type BreakPointLabel =
	'labtopMax'
	| 'labtopL'
	| 'labtop'
	| 'xlargeTablet'
	| 'largeTablet'
	| 'tablet'
	| 'smallTablet'
	| 'mobile'
	| 'mediumMobile'
	| 'smallMobile';

export const BreakPoint = {
	labtopMax: 1799,
	labtopL: 1199,
	labtop: 1023,
	xlargeTablet: 959,
	largeTablet: 839,
	tablet: 767,
	smallTablet: 599,
	mobile: 479,
	mediumMobile: 374,
	smallMobile: 320,
};

export type MediaType = {
	labtopMax: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	labtopL: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	labtop: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	xlargeTablet: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	largeTablet: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	tablet: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	smallTablet: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	mobile: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	mediumMobile: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	smallMobile: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
};

export default Object.keys(BreakPoint).reduce((acc, label): MediaType => {
	let label_: BreakPointLabel = ['labtopMax', 'labtopL','labtop', 'xlargeTablet', 'largeTablet', 'tablet', 'smallTablet', 'mobile', 'mediumMobile', 'smallMobile'].find(e => {
		if (e === label)
			return e;
	}) as BreakPointLabel;

	(acc as { [index: string]: (strings: TemplateStringsArray, ...expr: any[]) => FlattenSimpleInterpolation })[label] = (strings: TemplateStringsArray, ...expr: any[]) => css`
		@media (max-width: ${BreakPoint[label_]}px) {
			${css(strings, ...expr)};
		}
	`;
	
	return acc;
}, {} as MediaType);
