import TagManager from 'react-gtm-module';

type PageEvent = {
	event: string;
	page: string;
};

type TagManagerArgs = {
	dataLayer?: {
		userId?: string;
		userProject?: string;
		event?: string;
		page: string;
	};
	dataLayerName?: string;
};

const isProd = process.env.NODE_ENV === 'production';

export const gtmPageView = (url: string) => {
	const args: TagManagerArgs = {
		dataLayer: {
			event: 'pageview',
			page: url,
		},
	};

	TagManager.dataLayer(args);
};

export const initGtm = () => {
	if (process.browser && isProd && process.env.NEXT_PUBLIC_GTM_ID)
		TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
};
