import { css, FlattenSimpleInterpolation } from 'styled-components';

// 기기를 세 종률로 나눌 때 쓰는 파일
export type BreakPointLabel = 'labtop' | 'tablet' | 'mobile';
export enum BreakPointEnum {
	labtop,
	tablet,
	mobile,
}

export const BreakPoint = {
	labtop: 1199,
	tablet: 1023,
	mobile: 767,
};

export type BreakPointType = {
	labtop: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	tablet: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	mobile: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
};

export default Object.keys(BreakPoint).reduce((acc: any, label): BreakPointType => {
	let label_: BreakPointLabel = ['labtop', 'tablet', 'mobile'].find(e => {
		if (e === label)
			return e;
	}) as BreakPointLabel;

	(acc as { [index: string]: (strings: TemplateStringsArray, ...expr: any[]) => FlattenSimpleInterpolation })[label] = (strings: TemplateStringsArray, ...expr: any[]) => css`
		@media (max-width: ${BreakPoint[label_]}px) {
			${css(strings, ...expr)};
		}
	`;

	return acc;
}, {} as BreakPointType);
