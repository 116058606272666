import axios, { AxiosRequestConfig, Method } from 'axios';
import { IncomingMessage } from 'http';
import { getCookie } from '@src/lib/util/cookie';

export class RequestConfig {
	public baseURL: string;
	public headers?: any;
	public method?: Method;
	public url?: string;
	public data?: any;

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	public setToken(token?: any) {
		if (token) {
			this.headers = {
				Authorization: `Bearer ${token}`,
			};
		}
	}

	public get(path: string, config?: AxiosRequestConfig) {
		this.method = 'GET';
		this.url = this.baseURL + path;

		return { ...config, ...this };
	}

	public delete(path: string, config?: AxiosRequestConfig) {
		this.method = 'DELETE';
		this.url = this.baseURL + path;

		return { ...config, ...this };
	}

	public post(path: string, data?: any, config?: AxiosRequestConfig) {
		this.method = 'POST';
		this.url = this.baseURL + path;
		this.data = data;

		return { ...config, ...this };
	}

	public put(path: string, data?: any, config?: AxiosRequestConfig) {
		this.method = 'PUT';
		this.url = this.baseURL + path;
		this.data = data;

		return { ...config, ...this };
	}

	public patch(path: string, data?: any, config?: AxiosRequestConfig) {
		this.method = 'PATCH';
		this.url = this.baseURL + path;
		this.data = data;

		return { ...config, ...this };
	}
}

export const baseConfig = (auth?: boolean, req?: IncomingMessage) => {
	const requestConfig = new RequestConfig(process.env.NEXT_PUBLIC_CLIENT_HOME!);

	if (auth) {
		requestConfig.setToken(getCookie('accessToken', req));
	}

	return requestConfig;
};

export const restConfig = (auth?: boolean, req?: IncomingMessage) => {
	const requestConfig = new RequestConfig(process.env.NEXT_PUBLIC_REST_API_HOST!);

	if (auth) {
		requestConfig.setToken(getCookie('accessToken', req));
	}

	return requestConfig;
};

export const base = (auth?: boolean, req?: IncomingMessage) => {
	const requestConfig = new RequestConfig(process.env.NEXT_PUBLIC_CLIENT_HOME!);

	if (auth) {
		requestConfig.setToken(getCookie('accessToken', req));
	}

	return axios.create(requestConfig);
};

export default base;