import axios, { AxiosError, AxiosResponse } from 'axios';
import { IncomingMessage } from 'http';

import { buyListConfig, currencyData } from './config';
import { getCookie } from 'src/lib/util/cookie';
import { CustomError, HTTPError } from 'src/models/error/http';

async function buyList(userId: { userId: string })
{
	let res = await axios(buyListConfig(userId))
		.then(res => res.data.result)
		.catch((e: AxiosError<CustomError>) => {
			throw e.message;
		});

	return res;
}

async function currencyList()
{
	let res = await axios(currencyData())
		.then(res => res.data.result)
		.catch((e: AxiosError<CustomError>) => {
			throw e.message;
		});

	return res;
}

const UserService = {
	buyList,
	currencyList
};

export default UserService;
