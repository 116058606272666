import { Router } from 'next/router';
import { useState, useEffect } from 'react';
import _ from 'lodash';

import { gtmPageView } from 'src/lib/gtm/gtm';
import { BreakPoint, BreakPointLabel } from '@src/styles/media';

const isDev = process.env.NODE_ENV === 'development';

//사용하기 전에 process.browser 확인 후 사용할 것
export const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = _.throttle(() => setWidth(window.innerWidth), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return width;
};

export const useWindowHeight = () => {
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		const handleResize = _.throttle(() => setHeight(window.innerHeight), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return height;
};

export const useScrollDirection = (initialDirection: string) => {
	const [direction, setDirection] = useState(initialDirection);
	const [position, setPosition] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			if (Math.abs(position - window.scrollY) > 0) {
				setDirection(position > window.scrollY ? 'up' : 'down');
				setPosition(window.scrollY);
			}
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return direction;
};

export const useScrollY = () => {
	const [scrollY, setScrollY] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			setScrollY(window.scrollY);
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return scrollY;
};

export const useResize = (myRef: any) => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const handleResize = _.throttle(() => {
			setWidth(myRef.current.offsetWidth);
			setHeight(myRef.current.offsetHeight);
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return { width, height };
};

export const useIsDesktop = () => {
	const [isDesktop, setIsDesktop] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;
				
			const { innerWidth } = window;
			if (innerWidth >= 1024) {
				setIsDesktop(true);
			} else {
				setIsDesktop(false);
			}
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isDesktop;
};

export const useIsTablet = () => {
	const [isTablet, setIsTablet] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser) return;
			const { innerWidth } = window;
			if (innerWidth < BreakPoint.smallTablet || innerWidth >= BreakPoint.labtop) {
				setIsTablet(false);
			} else {
				setIsTablet(true);
			}
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isTablet;
};

export const useIsMoblie = () => {
	const [isMoblie, setIsMoblie] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;

			const { innerWidth } = window;
			if (innerWidth <= BreakPoint.smallTablet) 
				setIsMoblie(true);
			else
				setIsMoblie(false);
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	return isMoblie;
};

export const useDeviceLevel = () => {
	const [level, setLevel] = useState<BreakPointLabel>("labtopL");
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;

			const { innerWidth } = window;

			if (innerWidth >= BreakPoint.labtopL)
				setLevel("labtopL");
			else if (BreakPoint.labtop < innerWidth && innerWidth <= BreakPoint.labtopL)
				setLevel("labtop");
			else if (BreakPoint.xlargeTablet < innerWidth && innerWidth <= BreakPoint.labtop)
				setLevel("xlargeTablet");
			else if (BreakPoint.largeTablet < innerWidth && innerWidth <= BreakPoint.xlargeTablet)
				setLevel("largeTablet");
			else if (BreakPoint.tablet < innerWidth && innerWidth <= BreakPoint.largeTablet)
				setLevel("tablet");
			else if (BreakPoint.smallTablet < innerWidth && innerWidth <= BreakPoint.tablet)
				setLevel("smallTablet");
			else if (BreakPoint.mobile < innerWidth && innerWidth <= BreakPoint.smallTablet)
				setLevel("mobile");
			else if (BreakPoint.mediumMobile < innerWidth && innerWidth <= BreakPoint.mobile)
				setLevel("mediumMobile");
			else
				setLevel("smallMobile");
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	return level;
};

export const useGtmPageView = () => {
	useEffect(() => {
		if (!process.browser || isDev) return;

		const handleRouteChange = (url: string) => {
			gtmPageView(url);
		};

		Router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);
};

export const useAudio = (url: string) => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);
  
	const toggle = () => setPlaying(!playing);
  
	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);
  
	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);
  
	return [playing, toggle];
};

export const useCookie = (cookieName: string) => {
	const getCookieValue = (name: string) => {
		const nameString = name + "=";
		const value = document.cookie.split('; ').find(row => row.startsWith(nameString))?.split('=')[1];

		return value ? decodeURIComponent(value) : null;
	};
  
	const [cookieValue, setCookieValue] = useState(getCookieValue(cookieName));
  
	useEffect(() => {
		const interval = setInterval(() => {
			const newValue = getCookieValue(cookieName);

			if (newValue !== cookieValue)
				setCookieValue(newValue);
		}, 1000); // 매 초마다 쿠키 값을 확인합니다.
  
	  	return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 인터벌을 제거합니다.
	}, [cookieValue, cookieName]);
  
	return cookieValue;
};