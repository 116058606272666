import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MediaState } from "@src/types/reduxState"
import { BreakPointLabel } from "@src/styles/media";

const initialState: MediaState = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    deviceLevel: "labtopL",
};

const mediaReducer = createSlice({
    name: "media",
    initialState,
    reducers: {
        setMobile(state)
        {
            state.isMobile = true;
            state.isTablet = false;
            state.isDesktop = false;
        },
        setTabilt(state)
        {
            state.isMobile = false;
            state.isTablet = true;
            state.isDesktop = false;
        },
        setDesktop(state)
        {
            state.isMobile = false;
            state.isTablet = false;
            state.isDesktop = true;
        },
        setDiviceLevel(state, action: PayloadAction<BreakPointLabel>)
        {
            state.deviceLevel = action.payload;
        }
    }
});

export const { setMobile, setTabilt, setDesktop, setDiviceLevel } = { ...mediaReducer.actions };
export default mediaReducer.reducer;