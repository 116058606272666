import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserState } from "@src/types/reduxState"

const initialState: UserState = {
    isLogged: false,
    id: "",
    email: "",
    nickName: "",
    birthday: "",
    profileImage: "",
    loginDate: ""
};

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        // 로그인 시 유저 데이터 저장
        setLoggedUser(state, action: PayloadAction<UserState>)
        {
            state = { ...action.payload, isLogged: true};
            return state;
        },
    }
});

export const userActions = { ...userReducer.actions };

export default userReducer.reducer;