import { css } from 'styled-components';
import { ThemeType } from '@src/types/theme';

export const CONTAINER_SIZE = 1800;
export const GUTTER = 70;
export const MOBILE_GUTTER = 8;
export const MOBILE_HEADER = 96;
export const FOOTER_HEIGHT = 273;
export const MOBILE_FOOTER_HEIGHT = 64;

const MAIN_MIN_HEIGHT = FOOTER_HEIGHT + 'px';
const MOBILE_MAIN_MIN_HEIGHT = MOBILE_HEADER + MOBILE_FOOTER_HEIGHT + 'px';

const flexRow = css`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const flexCol = css`
	display: flex;
	flex-direction: column;
`;

const center = css`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const container = css`
	width: 100%;
	height: fit-content;
	/* padding: 80px 5%; */
	margin-left: auto;
	margin-right: auto;

	@media (min-width: ${ CONTAINER_SIZE }px) {
		max-width: ${ CONTAINER_SIZE }px;
	}
	@media (max-width: ${ CONTAINER_SIZE }px) {
		
	}
	@media (max-width: 1199px) {
		
		
	}
`;

const mobileFluidContainer = css`
	height: fit-content;

	@media (min-width: 1249px) {
		width: 100%;
		max-width: ${CONTAINER_SIZE}px;
		margin-left: auto;
		margin-right: auto;
	}
`;

const containerPaddingZero = css`
	height: fit-content;
	margin: 0 auto;
	width: 100%;
	max-width: ${CONTAINER_SIZE}px;
	@media (min-width: 1199px) {
		margin-left: auto;
		margin-right: auto;
	}
`;

const xlargeTabletPaddingZero = css`
	${({ theme }: { theme: ThemeType }) => theme.xlargeTablet`
		padding:0;
	`}
`;

const resRow = css`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: -${GUTTER}px;
	margin-right: -${GUTTER}px;
	${({ theme }: { theme: ThemeType }) => theme.breakpoint.tablet!`
		margin-left: -${MOBILE_GUTTER}px;
		margin-right: -${MOBILE_GUTTER}px;
	`}
`;

const buttonDefault = css`
	width: fit-content;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0;
	margin: 0;
	box-shadow: 0px 6px 5px 0 rgba(255, 124, 42, 0.2);
	border: none;
	outline: none;
	border-radius: 50px;
	cursor: pointer;
`;

const ellipsis = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	height: auto;
`;

const preWrap = css`
	white-space: pre-wrap;
	word-break: keep-all;
`;

const defaultMainStyle = css`
	width: 100%;
	min-height: 100vh;
	height: fit-content;

	.touched {
		transition: 0.2s;
		background-color: #f4f4f4;
	}

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.tablet!`
		min-height: calc(100vh - ${MOBILE_MAIN_MIN_HEIGHT});
	`}
`;

const bottomLine = css<{width?: string, color?: string}>`
	border-bottom-width: ${props => props.width ? props.width : "3px"};
	border-bottom-style: solid;
	border-color: ${props => props.color ? props.color : "#f1f1f1"};
`;

const verticalLine = css<{width?: string, color?: string}>`
	border-left-width: ${props => props.width ? props.width : "3px"};
	border-left-style: solid;
	border-color: ${props => props.color ? props.color : "#f1f1f1"};
`;

const notionStyle = css`
	max-width: 100%;
	font-family: Spoqa Han Sans Neo;
	span,
	p,
	div,
	h1,
	h2,
	h3 {
		font-family: Spoqa Han Sans Neo;
		white-space: break-spaces;
	}
	.notion-blank {
		min-height: 1em;
	}
	.notion-asset-wrapper {
		margin: 0.5em auto 0.5em;
	}
`;

const roundLuRd = css`
	border-radius: 0.5rem 0 0.5rem 0;
`;

const roundLdRu = css`
	border-radius: 0 0.5rem 0 0.5rem;
`;

const shadowWrap = css`
	border-radius: 30px;
	box-shadow: 0px 28px 21px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #ebebeb;
`;

const shadowElement = css`
	box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.07);
	border: solid 2px rgba(0, 0, 0, 0.05);
`;

const cssMixin = {
	flexRow,
	flexCol,
	resRow,
	container,
	preWrap,
	ellipsis,
	xlargeTabletPaddingZero,
	containerPaddingZero,
	buttonDefault,
	defaultMainStyle,
	bottomLine,
	verticalLine,
	center,
	mobileFluidContainer,
	notionStyle,
	roundLuRd,
	roundLdRu,
	shadowWrap,
	shadowElement,
};

export default cssMixin;
