import React, { Dispatch, useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import styled, { ThemeProvider } from 'styled-components';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import '@src/styles/global.css';

import initGA from 'src/lib/ga/initGA';

import { useDeviceLevel, useGtmPageView, useIsDesktop, useIsMoblie, useIsTablet } from 'src/hooks/util';
import { initGtm } from 'src/lib/gtm/gtm';
import { initLogrocket } from 'src/lib/logrocket/LogRocket';

import { isIE } from 'src/lib/util/browser'

import { getCookie, setCookie } from 'src/lib/util/cookie';
import AuthService from '@src/services/auth/AuthService';

import { persistor, wrapper } from "src/store/index"
import { useDispatch } from 'react-redux';
import * as mediaAction from '@src/store/media';
import * as currencyAction from '@src/store/currency';
import { lightTheme, darkTheme, GlobalStyles } from '@src/theme/default';
import { PayPalScriptProvider  } from "@paypal/react-paypal-js";
import { PersistGate } from 'redux-persist/integration/react';
import UserService from '@src/services/user/UserService';
import { AnyAction } from '@reduxjs/toolkit';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { initTranslate } from '@src/lib/public-lib';
import cssMixin from '@src/styles';
import { ThemeType } from '@src/types/theme';

type AppProps = {
	Component: any;
	pageProps: any;
	err: any;
};

function YulBgmApp({ Component, pageProps, err }: AppProps)
{
	const dispatch = useDispatch();
	const router = useRouter();

	const [ theme, setTheme ] = useState("dark") ;

	if(!isIE())
	{
		defineDevice(dispatch);
		// setCurrency();

		const isLoggedIn = getCookie('accessToken');
		if(isLoggedIn) // 로그인 되어있는 경우,
		{
			// refreshtoken이 유효한지 체크하고 유효하면 재로그인, 유효하지 않으면 강제 로그아웃
			// AuthService.refresh();
		}
		else
		{
			if (process.browser)
				localStorage.removeItem("persist:root");
		}

		useGtmPageView();

		useEffect(() => {
			// initGA(process.env.NEXT_PUBLIC_GA_APP_ID, Router);
			// initKakao();
			// initGtm();
			// initLogrocket();

			UserService.currencyList()
			.then(res => dispatch(currencyAction.setCurrency(res[0])))
		}, []);

		useEffect(() => {
			console.log("asdf");
			const translateElement = document.getElementById('google_translate_element');
	
			const addGoogleTranslateScript = () => {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
				document.body.appendChild(script);
			};

			(window as any).googleTranslateElementInit = () => {
				new (window as any).google.translate.TranslateElement(
					{
						pageLanguage: router.locale === 'ko' ? "ko" : "en",
						includedLanguages: 'ko,en',
						// layout: (window as any).google.translate.TranslateElement.InlineLayout.SIMPLE,
					},
					'google_translate_element'
				);
			};

			addGoogleTranslateScript();
		}, []);

		return (
			<ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
				<PayPalScriptProvider options={{ "client-id": process.env.NEXT_PUBLIC_PAYPAL_ID! }}/>
				<PersistGate persistor={ persistor } loading={ null }>
					<div className="app-container">
						<GlobalStyles />
						<Translator id="google_translate_element" />
						<Component {...pageProps} err={err} />
					</div>
				</PersistGate>
			</ThemeProvider>
		);
	}
	else
	{
		useEffect(() => {
			if (typeof window !== 'undefined') {
				alert('YULBGM는 인터넷 익스플로러를 지원하지 않고 있습니다.\n크롬, 사파리, 파이어폭스 등 다른 브라우저로 접속해주세요.\n감사합니다.');
			}
		}, []);

		return (
			<div></div>
		)
	}
}

function defineDevice(dispatch: Dispatch<AnyAction>)
{
	const isMobile: boolean = useIsMoblie();
	const isTablet: boolean = useIsTablet();
	const isDesktop: boolean = useIsDesktop();
	const deviceLevel = useDeviceLevel();

	useEffect(() => {
		if (isMobile)
			dispatch(mediaAction.setMobile());
		if (isTablet)
			dispatch(mediaAction.setTabilt());
		if (isDesktop)
			dispatch(mediaAction.setDesktop());

		dispatch(mediaAction.setDiviceLevel(deviceLevel));
	}, [isMobile, isTablet, isDesktop, deviceLevel]);
}

export default appWithTranslation(wrapper.withRedux(YulBgmApp));

const Translator = styled.div`
	${ cssMixin.container };
	display: flex;
	justify-content: end;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 30px);
	padding-right: 120px;

	${({ theme }: { theme: ThemeType }) => theme.largeTablet`
		transform: translate(-50%, 20px);
	`};
`;